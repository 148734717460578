const Routes = {
  home: { title: 'home', path: '/' },
  login: { title: 'login', path: '/login' },
  register: { title: 'register', path: '/register' },
  registerSuccess: { title: 'registerSuccess', path: '/register/notice' },
  verifyAccount: { title: 'verifyAccount', path: '/verify/:email/:token' },
  acceptInvite: { title: 'acceptInvite', path: '/invite/:email/:token' },
  forgotPassword: { title: 'forgotPassword', path: '/forgot' },
  resetPassword: { title: 'resetPassword', path: '/reset/:email/:token' },
  settings: { title: 'settings', path: '/settings' },
  search: { title: 'search', path: '/search' },
  logout: { title: 'logout', path: '/logout' },
};

export default Routes;
