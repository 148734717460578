import { ReactNode } from 'react';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Search from '@material-ui/icons/Search';
import Dashboard from '@material-ui/icons/Dashboard';
import Routes from './routes';

interface Option {
  text: string;
  icon: ReactNode;
  title: string;
  path: string;
}

export const TOKEN_KEY = 'token';

export enum ROLES {
  SUPER_USER = 1,
  ADMIN = 2,
  USER = 3,
}

export const AccountOptions: Option[] = [
  {
    text: 'Settings',
    icon: Settings,
    ...Routes.settings,
  },
  {
    text: 'Sign Out',
    icon: ExitToApp,
    ...Routes.logout,
  },
];

export const NavigationOptions: Option[] = [
  {
    text: 'Quick Find',
    icon: Search,
    ...Routes.search,
  },
  {
    text: 'Home',
    icon: Dashboard,
    ...Routes.home,
  },
];

export const GET_ALL_TESTS_DEFAULT_LIMIT = 10;
