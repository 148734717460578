import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import withApiInterceptors from './components/hoc/withApiInterceptors';
import ls from './utils/storage.utils';
import { TOKEN_KEY } from './common/constants';
import withErrorBoundary from './components/hoc/withErrorBoundary';
import Loader from './components/shared/Loader';
import Routes from './common/routes';

const Login = lazy(() => import('./pages/auth/Login'));
const Signup = lazy(() => import('./pages/auth/Register'));
const SignupNotice = lazy(() => import('./pages/auth/RegisterNotice'));
const VerifyAccount = lazy(() => import('./pages/auth/VerifyAccount'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));
const NotFound = lazy(() => import('./pages/error/NotFound'));
const Logout = lazy(() => import('./pages/auth/Logout'));
const AcceptInvite = lazy(() => import('./pages/auth/AcceptInvite'));
const PrivateLayout = lazy(() => import('./pages/layout'));

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const authenticated = !!ls.get(TOKEN_KEY);
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <Redirect to={Routes.login.path} />
      }
    />
  );
};

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loader page />}>
      <Switch>
        <Route exact path={Routes.login.path} component={Login} />
        <Route exact path={Routes.logout.path} component={Logout} />
        <Route exact path={Routes.register.path} component={Signup} />
        <Route exact path={Routes.registerSuccess.path} component={SignupNotice} />
        <Route exact path={Routes.verifyAccount.path} component={VerifyAccount} />
        <Route exact path={Routes.acceptInvite.path} component={AcceptInvite} />
        <Route exact path={Routes.forgotPassword.path} component={ForgotPassword} />
        <Route exact path={Routes.resetPassword.path} component={ResetPassword} />
        <PrivateRoute component={PrivateLayout} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default withErrorBoundary(withApiInterceptors(App));
